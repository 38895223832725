// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-get-quote-js": () => import("./../../../src/pages/get-quote.js" /* webpackChunkName: "component---src-pages-get-quote-js" */),
  "component---src-pages-thank-you-quote-js": () => import("./../../../src/pages/thank-you-quote.js" /* webpackChunkName: "component---src-pages-thank-you-quote-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-news-detail-template-js": () => import("./../../../src/templates/news-detail-template.js" /* webpackChunkName: "component---src-templates-news-detail-template-js" */),
  "component---src-templates-team-detail-js": () => import("./../../../src/templates/team-detail.js" /* webpackChunkName: "component---src-templates-team-detail-js" */)
}

